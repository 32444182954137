import React from 'react'
import { Link } from 'gatsby'
import Tags from './tags'
import Container from './container'
import Content from './content'
import classNames from 'classnames'

const FooterSection = ({ title, variant, children }) => {
  const sectionClassNames = classNames('footer__item', `footer__${variant}`)
  return (
    <section className={sectionClassNames}>
      <Container>
        {title && <h4 className="footer__title">{title}</h4>}
        {children}
      </Container>
    </section>
  )
}

const TagsSection = () => (
  <FooterSection title="Tags" variant="tags">
    <Tags tagType='outline' centered />
  </FooterSection>
)

const AboutSection = () => (
  <FooterSection title="About" variant="about">
    <Content>
      <p>
        "Hypertext Candy" は、Web開発に関するトピックを発信する技術ブログです。
      </p>
      <p>
        このサイトは <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby.js</a> で構築し <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a> から配信しています。
        <br />
        記事中の絵文字は <a href="https://www.emojione.com/" target="_blank" rel="noopener noreferrer">EmojiOne</a> にて無料配布されています。
      </p>
      <p><Link to="/privacy-policy">プライバシーポリシー</Link></p>
    </Content>
  </FooterSection>
)

export default function Footer () {
  return (
    <footer className="l-footer">
      <div className="footer">
        <TagsSection />
        <AboutSection />
        <section className="footer__bottom">
          <Content>
            &copy; 2018-present Masahiro Harada
          </Content>
        </section>
      </div>
    </footer>
  )
}
