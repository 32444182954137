import React from 'react'

const Container = ({ children }) => (
  <div className="l-main">{children}</div>
)

export default Container

export const ContainerRow = ({ children }) => (
  <div className="l-main-row">{children}</div>
)

export const ContainerCol = ({ children }) => (
  <div className="l-main-col">{children}</div>
)
