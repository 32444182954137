import React from 'react'
import Link from 'gatsby-link'

export default function Header ({ title, logo }) {
  return (
    <header className="l-header">
      <nav className="navbar">
        <Link className="navbar__logo" to="/">
          <img src={logo} alt={title} />
        </Link>
      </nav>
    </header>
  )
}
