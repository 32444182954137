import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

const Tag = ({label, href, type}) => {
  const tagClassNames = type
    ? classNames('article-tag', `article-tag--${type}`)
    : 'article-tag'

  return <Link className={tagClassNames} to={href}>{label}</Link>
}

const Tags = ({data, tags, centered, tagType}) => {
  const tagsClassNames = centered ? classNames('tags', 'tags--centered') : 'tags'

  const tagItems = typeof tags === 'undefined'
    ? data.allTagsJson.edges
    : data.allTagsJson.edges.filter(e => tags.includes(e.node.label))

  return (
    <ul className={tagsClassNames}>
      {
        tagItems.map(({ node: tag }) => (
          <li className="tags__item" key={tag.slug}>
            <Tag
              label={tag.label}
              href={`/tags/${tag.slug}`}
              type={tagType}
            />
          </li>
        ))
      }
    </ul>
  )
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.string
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allTagsJson {
          edges {
            node {
              label
              slug
            }
          }
        }
      }
    `}
    render={data => <Tags data={data} {...props} />}
  />
)
