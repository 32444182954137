import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import Logo from '../assets/logo.png'

import '../scss/styles.scss'

const layout = ({ children, fab }) => {
  return data => (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: data.site.siteMetadata.description },
        ]}
      >
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="https://cdn.hypertextcandy.com/logo.ico"
        />
        <html lang="ja" />
      </Helmet>
      <Header title={data.site.siteMetadata.title} logo={Logo} />
      {children}
      <Footer />
      {fab}
    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={layout(props)}
  />
)
